<!-- 语言切换 -->
<template>
  <div id="LanguageSet">
    <!-- 顶部导航 -->
    <van-nav-bar :title="$t('m_other.yysz')" left-arrow @click-left="onClickLeft" />
    <ul class="wrap">
      <li
        v-for="(item, index) in list"
        :key="index"
        @click="change(item)"
        class="iconfont"
        :class="{ active: item.sgin == active }"
      >
        {{ item.name }}
      </li>
    </ul>
    <footer class="footer">
      <button @click="handleCommand">{{$t('m_languageSettings.qhyt')}}</button>
    </footer>
  </div>
</template>

<script>
import { setup } from "@/language/index.js";
import LangStorage from "@/language/lang";
export default {
  name: "",
  data() {
    return {
      active: 0,
      list: [
        // {
        //   name: "简体中文",
        //   sgin: "zh",
        // },
        {
          name: "English",
          sgin: "english",
        },
        {
          name: "Tiếng Việt",
          sgin: "vietnamese",
        },
      ],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    console.log(this.$i18n.locale);
    this.active = this.$i18n.locale;
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    change(item) {
      this.active = item.sgin;
    },
    handleCommand() {
      setup(this.active);
      LangStorage.setLang(this.active);
      this.onClickLeft();
    }
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-nav-bar {
  background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important;
}

/deep/.van-nav-bar__title {
  color: #38373b !important;
}
/deep/.van-nav-bar .van-icon {
  color: #38373b !important;
}

#LanguageSet {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .wrap {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    padding: 0.15rem;
    box-sizing: border-box;

    li {
      box-sizing: border-box;
      width: 100%;
      height: 1rem;
      background-color: #373430;
      border-radius: 0.2rem;
      padding: 0 0.2rem;
      margin-bottom: 0.15rem;
      color: #fff;
      line-height: 1rem;
      position: relative;
      font-size: 0.3rem;
    }

    li.active::before {
      content: "\ea72";
      position: absolute;
      right: 0.3rem;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 0.4rem;
      color: #ceab77;
    }
  }

  .footer {
    width: 100%;
    height: 2rem;
    text-align: center;
    button {
      width: auto;
      padding: 0 0.8rem;
      height: 0.9rem;
      background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
      border-radius: 0.45rem;
      color: #373430;
      text-align: center;
      font-size: 0.3rem;
    }
  }
}
</style>